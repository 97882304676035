<template>
  <!-- editerValue -->
  <editor
    @init="editorInit"
    lang="java"
    :options="editorOptions"
    theme="chrome"
    width="100%"
    height="100%"
    class="editor-content"
  ></editor>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { getData, setData, $success, $warning, $error } from '@/utils/save'
import eventBus from '@/utils/event-bus'
export default {
  data() {
    return {
      editorOptions: {
        // 设置代码编辑器的样式
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        tabSize: 2,
        fontSize: 16,
        showPrintMargin: false, //去除编辑器里的竖线
        autoScrollEditorIntoView: true,
        highlightSelectedWord: false
        // maxLines:1000,
        //  minLines:20,
      },
      //编辑器
      editor: ''
    }
  },
  created() {
    eventBus.$on('langChange', () => {
      if (this.$store.state.editableTabs.length === 0) {
        this.editor.setValue(this.$t('el.main.index.form.placeholder5'))
      }
    })
  },
  computed: {
    // word:{
    //   get(){
    //     return this.$store.state.editerValue;
    //   },
    //    ste(newVal){
    //      this.word=newVal;
    //    }
    // },
    editableTabs: {
      get() {
        return this.$store.state.editableTabs
      }
    },
    ...mapState(['editableTabsValue', 'editableTabs', 'editerValue'])
  },
  watch: {
    editableTabs(val) {
      if (val.length <= 0) {
        this.$store.commit('changeIsChangeEditror', false)
        this.editor.setValue(this.$t('el.main.index.form.placeholder5'))
        this.editor.gotoLine(1)
        this.editor.setReadOnly(true)
      } else {
        this.editor.setReadOnly(!this.$store.state.user)
      }
    }
  },
  components: {
    editor: require('vue2-ace-editor')
  },
  methods: {
    ...mapMutations(['getEditor']),
    editorInit: function(editor) {
      // const self=this;
      require('brace/theme/chrome')
      require('brace/ext/language_tools') //language extension prerequsite...
      require('brace/mode/yaml')
      require('brace/mode/java')
      require('brace/mode/less')
      // require("brace/snippets/java");
      require('brace/theme/monokai')
      require('brace/ext/emmet')

      this.editor = editor
      this.getEditor(editor)
      const self = this
      //  editor.setReadOnly(true);
      editor.getSession().setUseWrapMode(false)
      let time = 1
      this.editor.getSession().on('change', () => {
        //  if( editor.setReadOnly()){
        //     $error("请选择需要编辑的文档后再编辑");
        //  }
        // if (
        //   self.$store.state.editableTabs.length === 0 &&
        //   self.isChangeEditror
        // ) {
        //   editor.setReadOnly(true);
        //   editor.setValue("");
        //   if (time == 1) {
        //     $error("请选择需要编辑的文档后再编辑");
        //   }
        //   time++;
        //   return;
        // } else {
        //   editor.setReadOnly(false);
        // }

        if (!this.$store.state.isChangeEditror) return
        // let fileData = JSON.parse(getData("fileDate"));
        let fileData = self.$store.state.fileDate
        fileData = fileData.map(item => {
          if (item.path === self.editableTabsValue) {
            return { ...item, content: editor.getValue() }
          }
          return item
        })
        // setData("fileDate", JSON.stringify(fileData));
        this.$store.commit('changeFileDate', fileData)
        self
          .$('.ace-chrome .ace_marker-layer .ace_selection')
          .css({ background: 'transportant!important' })
      })
    }
  }
}
</script>
<style lang="scss">
.editor-content {
  .ace_numeric {
    color: #cd5f00 !important;
  }
  .ace_keyword {
    color: #e5a0dc !important;
  }
  .ace_boolean {
    color: #76e5d6 !important;
  }
  .ace_string {
    color: #f1bb07ec !important;
  }
  .ace_comment {
    color: #418985 !important;
  }
  .ace_function {
    color: rgb(230, 154, 63) !important;
  }
}
</style>
<style scoped lang="scss">
// 左边数字
::v-deep .ace_layer.ace_gutter-layer.ace_folding-enabled {
  background: #272c37;
  color: #828b9f;
  font-weight: 400;
  font-size: 12px;
}

::v-deep .ace_gutter-cell {
  padding: 0;
  font-size: 12px;
  text-align: center;
}

//字颜色
::v-deep .ace_content {
  color: #fff;
  background-color: #343c4f;
}

//左右导航条样式
::v-deep .ace_scrollbar-v::-webkit-scrollbar {
  width: 5px;
}

::v-deep .ace_scrollbar-h::-webkit-scrollbar {
  height: 5px;
}

::v-deep .ace_scrollbar-v,
::v-deep .ace_scrollbar-h {
  background-color: #343c4f;
}

::v-deep .ace_scrollbar-h {
  right: 0 !important;
  height: 9px !important;
}
::v-deep .ace_scrollbar-v {
  width: 7px !important;
  bottom: 8px !important;
}
::v-deep .ace_scroller {
  right: 6px !important;
  bottom: 6px !important;
}
.editor-content {
  background-color: transparent !important;
}

::v-deep .ace_scrollbar-v::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
  background-color: #3d4659;
}

::v-deep .ace_scrollbar-v::-webkit-scrollbar-track {
  -webkit-box-shadow: insert 0 0 5px #3d4659;
  box-shadow: 0;
  background-color: #343c4f;
}

::v-deep .ace_scrollbar-h::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px #3d4659;
  background-color: #3d4659;
}

::v-deep .ace_scrollbar-h::-webkit-scrollbar-track {
  -webkit-box-shadow: insert 0 0 5px #3d4659;
  box-shadow: 0;
  background-color: #343c4f;
}

::v-deep .ace_invisible.ace_invisible_space.ace_invalid {
  visibility: hidden;
}

//cursor样式
::v-deep .ace_layer.ace_cursor-layer .ace_cursor {
  color: #fff;
}

::v-deep .ace_indent-guide {
  background: none right repeat-y !important;
}

//集合xianxian
::v-deep .ace_active-line {
  background: #343c4f !important;
}

// ::v-deep  .ace_selection{
//   background-color:rgba($color: #000000, $alpha: 0) !important;
// }
</style>
