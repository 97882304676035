<template>
    <div>
        <div class="header">
            <!-- <div class="top-tip">To ensure your experience, please use a desktop browser to access <span></span> </div> -->
            <div class="left">
                <img src="@/assets/images/userImg/logo-en.png" class="logo-img" alt="">
                <!-- <div class="word"></div> -->
                <div class="title" v-html="$t('el.projectName1')"></div>

                <!-- <div class="desc" @click="showUpdateLog = true">{{ $t('el.changeLog') }}</div> -->
            </div>
            <div class="right">
                <div class="feedback" @click="openFeedBack">
                    <img src="@/assets/images/header/info.svg" alt="" style="margin-right: 5px">
                    {{ $t('el.feedback') }}
                </div>
                <div class="desc" @click="openSameTab">
                    <img src="@/assets/images/header/doc.svg">
                    {{ $t('el.detectDocument') }}
                </div>
                <div class="desc" @click="changeShowResult(true)" v-if="userName">
                    <img src="@/assets/images/header/history.svg">
                    {{ $t('el.main.index.result.history') }}
                </div>
                <div class="help" v-show='false'>
                    <a href="https://beosin.obs.cn-east-3.myhuaweicloud.com/vass/vass-help.pdf" target="_blank">{{
                            $t('el.help')
                    }}</a>
                </div>

                <div class="user" v-if="userName">
                    <!-- <mySvg icon="#icon-use"></mySvg> -->
                    <el-popover placement="top" width="40" v-model="visible" trigger="hover" popper-class="return">
                        <div class="word" style="text-align: center" @click="returnDialoge = true">
                            {{ $t('el.logout') }}
                        </div>
                        <span slot="reference">
                            <img src="@/assets/images/header/avatar.svg" alt="" style="margin-right: 5px">
                            <span class='userName'> {{ userName }}</span>
                        </span>
                        <!-- <el-button slot="reference">删除</el-button> -->
                    </el-popover>
                </div>
                <div class="button" @click="toLogin" v-else>
                    {{ $t('el.login.login') }}
                </div>
                <div class="user">
                    <el-popover placement="top" width="40" v-model="visibleLang" trigger="hover"
                        :popper-class="popoverClass">
                        <div class="lang-word-1" style="text-align: center"
                            @mouseenter="popoverClass = 'lang-popover-1'" @mouseleave="popoverClass = 'lang-popover'"
                            @click="changeLang('zh_CN')">
                            中文
                        </div>
                        <div class="lang-word" style="text-align: center" @click="changeLang('en_US')">
                            English
                        </div>
                        <span slot="reference">
                            {{ $i18n.locale === 'zh_CN' ? '中文' : 'EN' }} <i class="el-icon-caret-bottom"></i>
                        </span>
                    </el-popover>
                </div>
            </div>
        </div>
        <!-- 更新日志 -->
        <div class="showLog">
            <el-dialog :visible.sync="showUpdateLog" width="1000px">
                <template slot="title"> {{ $t('el.update.title') }}</template>
                <div>
                    <el-timeline>
                        <el-timeline-item>
                            <div class="left">
                                <div class="top">V3.0</div>
                                <div class="time">2021.6.17</div>
                            </div>
                            <div class="right">
                                <div class="title">{{ $t('el.update.add') }}</div>
                                <div class="line">1、{{ $t('el.update.addInfo1') }}</div>
                                <div class="line">2、{{ $t('el.update.addInfo2') }}</div>
                                <div class="line">3、{{ $t('el.update.addInfo3') }}</div>
                                <div class="title advantage">{{ $t('el.update.optimization') }}</div>
                                <div class="line">1、{{ $t('el.update.optimizationInfo1') }}</div>
                                <div class="line">2、{{ $t('el.update.optimizationInfo2') }}</div>
                            </div>
                        </el-timeline-item>
                        <!-- <el-timeline-item>
                          <div class="left">
                            <div class="top">V3.0</div>
                            <div class="time">2020.10.24</div>
                          </div>
                          <div class="right">
                            <div class="title advantage">优化</div>
                            <div class="line">优化了11111111111111</div>
                            <div class="line">优化了11111111111111</div>
                            <div class="line">优化了11111111111111</div>
                            <div class="line">优化了11111111111111</div>
                          </div>
                        </el-timeline-item>
                        <el-timeline-item>
                          <div class="left">
                            <div class="top">V3.0</div>
                            <div class="time">2020.10.24</div>
                          </div>
                          <div class="right">
                            <div class="title del">删除</div>
                            <div class="line">删除了11111111111111</div>
                            <div class="line">删除了11111111111111</div>
                            <div class="line">删除了11111111111111</div>
                            <div class="line">删除了11111111111111</div>
                          </div>
                        </el-timeline-item> -->
                    </el-timeline>
                </div>
            </el-dialog>
        </div>

        <!-- 退出登录弹出层 -->
        <div class="del">
            <el-dialog :visible.sync="returnDialoge" width="410px">
                <div class="body">
                    <div class="le">
                        <img src="@/assets/images/triangle.png" />
                    </div>
                    <div class="ri">
                        <div class="top"> {{ $t('el.confirmLogout') }}？</div>
                    </div>
                </div>
                <div slot="footer" class="dialog-footer">
                    <div @click="returnLogin">
                        {{ $t("el.dialog.confirm") }}
                    </div>
                    <div @click="returnDialoge = false">
                        {{ $t("el.dialog.cancel") }}
                    </div>
                </div>
            </el-dialog>
        </div>
        <!--    需求反馈弹窗    -->
        <div class="check">
            <el-dialog custom-class="table feedback-dialog" :title='$t("el.feedbackDialog.title")' width="1180px"
                :close-on-click-modal="false" :visible.sync="showFeedBack">
                <div class="feedback-dialog-body">
                    <h3 class="label">
                        <span style="color: red">*</span>
                        {{ $t("el.feedbackDialog.name") }}
                    </h3>
                    <el-input class="label" :placeholder="$t('el.feedbackDialog.nameP')"
                        v-model="feedbackForm.userLabel">
                    </el-input>

                    <h3 class="label">
                        <span style="color: red">*</span>
                        {{ $t("el.feedbackDialog.content") }}
                    </h3>
                    <el-input class="label" type="textarea" :rows="4" :placeholder="$t('el.feedbackDialog.contentP3')"
                        v-model="feedbackForm.requireTitle">
                    </el-input>

                    <h3 class="label">
                        <span style="color: red">*</span>
                        {{ $t("el.feedbackDialog.description") }}
                    </h3>
                    <el-input class="label" type="textarea" :rows="4"
                        :placeholder="$t('el.feedbackDialog.descriptionP3')" v-model="feedbackForm.requireContent">
                    </el-input>
                    <div class="upload">
                        <h3 class="label" style="margin-right: 10px">
                            {{ $t("el.feedbackDialog.attachment") }} :
                        </h3>
                        <el-upload class="uploadArea" ref="myUpload"
                            :action="baseUrl + '/api/af//help/common/file/upload/one'" :on-exceed="handleExceed"
                            :before-remove="beforeRemove" :on-remove="handleRemove" :headers="headers"
                            :auto-upload="false" :on-change="changeFile" multiple :limit="5"
                            accept=".rar, .zip, .doc,.jpg, .png" :file-list="fileList">
                            <div class="upload-btn">
                                <i class="el-icon-upload2" style="margin-right: 10px"></i>
                                {{ $t('el.feedbackDialog.upload') }}
                            </div>
                            <div slot="tip" class="el-upload__tip">
                                <p style="font-weight: 600;font-size: 14px;margin-bottom: 5px">{{
                                        $t('el.feedbackDialog.extensionsInfo')
                                }} : <span style="font-weight: normal">{{
        $t('el.feedbackDialog.extensions')
}}</span></p>
                                <p style="font-weight: 600;font-size: 14px;margin-bottom: 5px">{{
                                        $t('el.feedbackDialog.limitSizeInfo')
                                }} : <span style="font-weight: normal">{{
        $t('el.feedbackDialog.limitSize')
}}</span></p>
                                <p style="font-weight: 600;font-size: 14px;margin-bottom: 5px">{{
                                        $t('el.feedbackDialog.limitQuantityInfo')
                                }} : <span style="font-weight: normal">{{
        $t('el.feedbackDialog.limitQuantity')
}}</span></p>
                            </div>
                        </el-upload>
                    </div>
                </div>
                <div slot="footer" class="dialog-footer feedback-dialog-footer">
                    <div @click="submitFeedBack" style="margin-right: 30px">
                        {{ $t("el.dialog.submit") }}
                    </div>
                    <div @click="showFeedBack = false">
                        {{ $t("el.dialog.cancel") }}
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import { clearData, getData, setData } from "@/utils/save.js";
import { mapState, mapMutations } from "vuex";
import mySvg from "./svg.vue";
import { getStore } from "../../../utils/utils";
import { addFeedbackRequire } from "../../../api/feedback";
import eventBus from "@/utils/event-bus";
export default {
    inject: ['toLogin'],
    data() {
        return {
            showUpdateLog: false,
            visible: false,
            returnDialoge: false,
            visibleLang: false,
            popoverClass: 'lang-popover',
            showFeedBack: false,
            headers: {
                Authorization: 'Bearer ' + getStore('token')
            },
            feedbackForm: {
                systemName: "Vaas",
                systemNum: "5",
                file: [],
                requireContent: "",
                requireTitle: "",
                userLabel: '',
            },
            fileList: [],
            uploadFileList: [],
            baseUrl: process.env.VUE_APP_BASE_API
        };
    },
    components: {
        mySvg,
    },
    computed: {
        userName() {
            const user = this.$store.state.user
            return user?.userName || user?.username
        }
    },

    methods: {
        ...mapMutations(["changeShowResult"]),
        openSameTab() {
            window.open('https://beosinofficial.gitbook.io/vaas-english', 'Documents')
        },
        //退出登录
        returnLogin() {
            clearData("token");
            this.$store.commit('changeEditableTabs', []);
            sessionStorage.clear();
            this.$store.commit('changeUser', null);
            this.returnDialoge = false
            window.location.reload()
        },
        changeLang(lang) {
            this.setStore('language', lang)
            this.$i18n.locale = lang
            // document.title = this.$t('el.title');
            eventBus.$emit("langChange")
        },
        openFeedBack() {
            this.feedbackForm = {
                systemName: "Vaas",
                systemNum: "5",
                file: [],
                requireContent: "",
                requireTitle: "",
                userLabel: '',
            }
            this.fileList = []
            this.uploadFileList = []
            this.showFeedBack = true
        },
        handleExceed() {
            this.$message.warning(this.$t('el.feedbackDialog.limitQuantity'));
        },
        handleRemove(file, fileList) {
            this.fileList = [];
            fileList.map(i => {
                this.fileList.push({
                    name: i.name,
                    url: i.name
                })
            })
        },
        beforeRemove(file) {
            let list = [];
            this.uploadFileList.map(i => {
                if (i.filename !== file.name) {
                    list.push(i);
                }
            })
            this.uploadFileList = list;
        },
        beforeUpload(file) {
            console.log(1,file)
            return false
        },
        changeFile(file) {
            if (file.size > 10 * 1024 * 1024) {
                this.$refs.myUpload.clearFiles() //清除文件对象
                this.$message.warning(this.$t('el.feedbackDialog.limitSize'));
                let list = [];
                this.fileList.map(i => {
                    list.push({ name: i.name, url: i.url })
                })
                this.fileList = list;
            } else {
                this.$refs.myUpload.clearFiles() //清除文件对象
                this.fileList.push({ name: file.name, url: file.url })
                let reader = new FileReader()
                reader.readAsDataURL(file.raw)
                reader.onload = () => {
                    const base64 = reader.result.split(';base64,')[1]
                    if(!base64) {
                        
                    }
                    if(reader.result)
                    this.uploadFileList.push({
                        base64File: reader.result.split(';base64,')[1],
                        filename: file.name
                    })
                }
                reader.onerror = function (error) {
                    console.log('Error: ', error)
                }
            }
        },
        /**
         * 表单校验方法
         */
        verificationForm() {
            if (!this.feedbackForm.userLabel) {
                this.$message.warning(this.$t('el.feedbackDialog.nameP'));
                return false
            }
            if (this.feedbackForm.userLabel.length > 30) {
                this.$message.warning(this.$t('el.feedbackDialog.nameP2'));
                return false
            }
            if (!this.feedbackForm.requireTitle) {
                this.$message.warning(this.$t('el.feedbackDialog.contentP'));
                return false
            }
            if (this.feedbackForm.requireTitle.length > 500) {
                this.$message.warning(this.$t('el.feedbackDialog.contentP2'));
                return false
            }
            if (!this.feedbackForm.requireContent) {
                this.$message.warning(this.$t('el.feedbackDialog.descriptionP'));
                return false
            }
            if (this.feedbackForm.requireContent.length > 500) {
                this.$message.warning(this.$t('el.feedbackDialog.descriptionP2'));
                return false
            }
            const emptyFile = this.uploadFileList.find(it => !it.base64File)
            if(emptyFile) {
                this.$message.error(`${this.$t('el.feedbackDialog.emptyfile')}: ${emptyFile.filename}`)
                return false
            }

            return true
        },
        /**
         * 提交反馈方法
         */
        submitFeedBack() {
            const _this = this
            if (!_this.verificationForm()) return
            _this.feedbackForm.file = _this.uploadFileList
            if (!this.userName) {
                this.toLogin()
                return
            }
            addFeedbackRequire(_this.feedbackForm).then(res => {
                if (!res) return
                if (res.code === '100000000') {
                    _this.$message.success({
                        message: res.message,
                        showClose: true,
                    });
                    _this.showFeedBack = false
                } else {
                    _this.$message.error(res.message);
                }

            })
        }
    },
    mounted() {
        if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
            this.$message({ message: 'To ensure your experience, please use a desktop browser to access ', type: 'warning', duration: 0, showClose: true })
        }
    }
};
</script>
<style scoped lang='scss'>
.header {
    width: 100%;
    height: 67px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    background: #141720;

    .user {
        ::v-deep .el-popover__reference {
            display: flex;
            align-items: center;
        }
    }

    .top-tip {
        position: absolute;
    }

    .left {
        margin-left: 51px;
        line-height: 67px;
        display: flex;

        div {
            margin-right: 15px;
        }

        .word,
        .title {
            font-size: 18px;
            margin-left: 8px;
        }

        .word {
            font-weight: 700;
        }


    }

    .right {
        display: flex;
        align-items: center;

        .desc {
            font-weight: 400;
            cursor: pointer;
            margin-right: 30px;
            font-size: 14px;
            color: #fff;
            padding: 0 14px;
            height: 32px;
            background: #272C37;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                margin-right: 5px;
            }
            &:hover {
                opacity: 0.8;
            }
        }

        .button {
            width: 86px;
            height: 31px;
            background: #465ebf;
            opacity: 1;
            border-radius: 6px;
            line-height: 31px;
            text-align: center;
            cursor: pointer;
        }

        .button,
        .feedback,
        .help {
            font-size: 14px;
            font-weight: 400;
        }

        .feedback {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                opacity: 0.8;
            }
        }

        .help,
        .feedback {
            color: #fff;
            line-height: 67px;

            a {
                text-decoration: none;
                color: #6e758a;
            }
        }

        .help,
        .user,
        .feedback,
        .button {
            margin-right: 40px;
        }

        ::v-deep .user {
            display: flex;
            line-height: 67px;
            cursor: pointer;
            &:hover{
                opacity: 0.8;
            }
            .icon {
                font-size: 15px;
                color: #6e758a;
                // height: 67px;
                margin-right: 5px;
            }

            .return {
                text-align: center;
            }

            span {
                color: #fff;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

//显示日志
.showLog {
    ::v-deep .el-dialog {
        margin-top: 78px !important;
        border-radius: 3px;
        overflow: hidden;
    }

    ::v-deep .el-dialog,
    .el-pager li {
        background: #2e333e;
    }

    ::v-deep .el-dialog__header {
        background: #2e333e;
        color: #d3dcf5;
        font-size: 20px;

        .el-dialog__headerbtn .el-dialog__close {
            color: white;
            font-size: 20px;
        }
    }

    ::v-deep .el-dialog__body {
        background: #2e333e;

        .el-timeline-item__tail {
            left: 244px !important;
            position: absolute;
            border-left: 2px dotted #356ecb;
        }

        .el-timeline-item__node {
            left: 239px;
            background-color: #448aff;
            width: 11px;
            height: 11px;
        }

        .left {
            position: absolute;
            left: 105px;

            .top {
                color: #448aff;
                font-size: 28px;
                font-weight: bold;
            }

            .time {
                color: #d3dcf5;
                font-size: 14px;
            }
        }

        .right {
            position: relative;
            left: 238px;

            .title {
                font-size: 18px;
                color: #d4ba10;
                line-height: 24px;
            }

            .advantage {
                color: #00b5af;
            }

            .del {
                color: #f84444;
            }

            .line {
                font-size: 14px;
                line-height: 19px;
                color: #d3dcf5;
            }
        }
    }

    ::v-deep .el-timeline-item__content .left,
    .right {
        display: block;
    }

    ::v-deep .el-timeline-item__content .left {
        line-height: 27px;
    }
}

.logo-img {
    height: 57px;
    margin-right: 10px;
    margin-top: 10px;
    backface-visibility: hidden;
}

// //删除弹出层
::v-deep .del {
    .el-dialog__header {
        height: 30px;
        padding: 12px 0;
    }

    .body {
        display: flex;
        align-items: center;

        .le {
            margin-left: 95px;

            img {
                width: 46px;
            }
        }

        .ri {
            font-weight: 400;
            margin-left: 15px;
        }
    }
}

::v-deep .el-dialog__footer {
    height: 80px;

    .dialog-footer {
        display: flex;
        padding: 15px 40px 0;
        justify-content: space-between;

        div {
            width: 100px;
            height: 30px;
            border: 1px solid #30364e;
            border-radius: 3px;
            color: #2a2f45;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            text-align: center;
            cursor: pointer;
            box-sizing: border-box;
        }

        div:first-child {
            background: #30364e;
            color: #fff;
        }
    }
}

::v-deep .el-dialog__footer {
    .dialog-footer {
        div:hover {
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.37);
        }
    }
}

.feedback-dialog-body {
    display: flex;
    flex-direction: column;

    .label {
        margin-bottom: 13px;
    }

    .upload {
        display: flex;
        align-items: flex-start;
    }

    .upload-btn {
        width: 106px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.15);
        text-align: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.37);
        }
    }
}

.feedback-dialog {
    .feedback-dialog-footer {
        justify-content: center;
    }
}
</style>
